import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { HAS_ANCHOR_BAR } from '@/store/getters';

import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import { getComponentProps } from '@/utils/vueUtils';
import { ImageSizes, ImageSize } from '@/common/interfaces/SizeBreakpoints';
import DottedLine from '@/assets/icons/dotted-line.svg?inline';
import Pin from '@/assets/icons/pin-filled.svg?inline';

import HeroShortBlockViewModel from './HeroShortModule.types';

import { getResizeImage } from '@/utils/commonUtils';

const HeroShortModuleProps = Vue.extend({
  props: getComponentProps<HeroShortBlockViewModel>({
    Title: 'Destinations',
    Image: {
      Url: getResizeImage('underwater.jpg'),
      Alt: 'Underwater scene',
    },
    Text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit quam lacus suspendisse faucibus interdum posuere lorem',
    Location: 'Alaska',
  }),
});

@Component({
  components: {
    ResponsiveImage,
    DottedLine,
    Pin,
  },
})
export default class HeroShortModule extends HeroShortModuleProps {
  @Getter(HAS_ANCHOR_BAR) hasAnchorBar!: boolean;

  imageSizes: ImageSizes = {
    def: new ImageSize([1920, 1080]),
    sizes: {
      sm: new ImageSize([768, 1024]),
      md: new ImageSize([992, 744]),
      lg: new ImageSize([1199, 900]),
      xl: new ImageSize([1920, 1080]),
    },
  };
}
